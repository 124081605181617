import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-if-my-teenager-has-previously-had-depression"
    }}>{`What if my teenager has previously had depression?`}</h1>
    <p>{`Below is a list of self-help strategies that can help reduce the risk of your teenager experiencing depression again.`}<sup>{`2`}</sup></p>
    <p>{`Encourage your teenager to:`}</p>
    <ul>
      <li parentName="ul">{`Engage in exercise or physical activity.`}</li>
      <li parentName="ul">{`Do something they enjoy.`}</li>
      <li parentName="ul">{`Practise good sleep habits.`}</li>
      <li parentName="ul">{`Try to remain involved in purposeful activities for at least a small part of every day.`}</li>
      <li parentName="ul">{`Eat a healthy, balanced diet.`}</li>
      <li parentName="ul">{`Talk over problems or feelings with someone who is supportive and caring.`}</li>
      <li parentName="ul">{`Reward themselves for reaching a small goal.`}</li>
      <li parentName="ul">{`Let their family and friends know how they are feeling so that people close to them are aware of what they are going through.`}</li>
      <li parentName="ul">{`Engage in activities that give them a feeling of achievement.`}</li>
      <li parentName="ul">{`Enlist a trusted friend or relative to help them get out and about or do activities.`}</li>
      <li parentName="ul">{`Make sure they get out of the house and are active for at least a short time each day.`}</li>
      <li parentName="ul">{`Make a list of strategies that have worked in the past for depression and use them.`}</li>
      <li parentName="ul">{`Learn relaxation techniques (e.g. meditation, progressive muscle relaxation training).`}</li>
    </ul>
    <h3 {...{
      "id": "what-if-my-teenager-has-previously-had-clinical-anxiety"
    }}>{`What if my teenager has previously had clinical anxiety?`}</h3>
    <p>{`The module `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`From surviving to thriving: Helping your teenager deal with anxiety`}</i></GoTo>{` has some strategies which may be helpful for parents to support their teenagers if they have problems with anxiety.`}</p>
    <sup>
  2. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      